import React from 'react';
import ContactForm from '../components/ContactForm';


const Contact = () => {
    return (
        <div className= 'contactPage'>
            <ContactForm />
        </div>
    );
}

export default Contact;