import { Link } from "react-router-dom";

const Background = (props) => {
  return (
    <div className='imageContainer'>
        <img className='bgImage'src="image0.jpeg" alt="" />
    </div>
  );
}

export default Background;