import React from 'react';
import Overlay from "../components/Overlay"

function Index(props) {
    return (
        <div className='homePage'>
            <Overlay />
        </div>
    );
}

export default Index;